import Modal from "react-modal"
import close from "../../assets/icons/close.svg"
import linePatterns from "../../assets/images/linePatterns.svg"
import Lottie from "lottie-react";
import success from "../../assets/json/newSuccess.json";

const customStyles = {
	overlay:{
		backgroundColor: 'rgba(255, 255, 255, 0.75)',
	},
	content: {
		padding: "0px",
	},
};

const lottieStyle = {
	height: "200px"
}


export default function SuccessModal(props) {

	return(
		<Modal
			isOpen={props.isOpen}
			style={customStyles}
			className="w-11/12 sm:w-8/12 md:w-1/3 !no-outline"
			overlayClassName="h-screen w-screen left-0 top-0 bottom-0 right-0 fixed flex justify-center items-center bg-app-grey-20/[0.2]"
			contentLabel={"Success"}>
			<div className="bg-app-orange-10 rounded p-5 pb-20 no-outline bg-no-repeat bg-cover bg-left-top" style={{backgroundImage: `url(${linePatterns})`}}>
				<div className="flex justify-end"><button onClick={() => props.close()}><img src={close} alt="close" /></button></div>
				<Lottie animationData={success} loop={true} autoPlay={true} style={lottieStyle} />
				<div className="text-center mt-8">
					<p className="font-bold text-app-black-90 mb-4 text-xl">
						Great! you’re on the waitlist
					</p>
					<p className=" text-app-black-60">
						We’ll be in touch when Tabulerasa goes live.
					</p>
				</div>
				<div className="flex justify-center mt-10">
					<button className="round-btn bg-app-orange-70 text-app-black-100 px-10 py-3" onClick={() => props.close()}>Continue</button>
				</div>
			</div>
		</Modal>
	)
}
