import image1 from "./../assets/images/image1.png"
import image2 from "./../assets/images/image2.png"
import image3 from "./../assets/images/image3.png"
import benefit from "./../assets/icons/benefit.svg"
import join from "./../assets/icons/join.svg"
import logo3d from "./../assets/icons/3dlogo.svg"
import { emailValid } from "../atoms/utils"
import { useState } from "react"

export default function Action(props) {
	const[email, setEmail] = useState("")
	const[error, setError] = useState("")
	const empty = (email === "")

	function validateEmail() {
		if (empty) {
			setError("Please enter an email address")
			return false
		} else if (!emailValid(email)){
			setError("Please enter a valid email address")
			return false
		}else{
			return true
		}
	}

	return(
		<div className="py-20 min-h-screen mx-5 sm:mx-20" id="call-to-action">
			<h2 className="font-grotesk text-app-black-50 text-4xl font-bold text-center">Join <span className="text-app-black-100">Tabulerasa</span> to</h2>
			<div className="grid grid-cols-1 md:grid-cols-3 my-16 gap-10">
				<div className="benefit-card group">
					<div className="p-5 pb-0" style={{backgroundImage: `url(${benefit})`}}>
						<div className="h-80 benefit-card__image"  style={{backgroundImage: `url(${image1})`}}></div>
					</div>
					<div className="benefit-card__textarea">
						<p className="font-bold">Get access to resources on the go</p>
						<p className="benefit-card__subtext">Whether you’re in the office or on the move, you can log on to Tabulerasa from anywhere</p>
					</div>
				</div>
				<div className="benefit-card group">
					<div className="p-5 pb-0" style={{backgroundImage: `url(${benefit})`}}>
						<div className="h-80 benefit-card__image"  style={{backgroundImage: `url(${image2})`}}></div>
					</div>
					<div className="benefit-card__textarea">
						<p className="font-bold">Find authentic and verifiable information</p>
						<p className="benefit-card__subtext">Get insights from well-informed lawyers about legal matters and find directories to help you navigate structures</p>
					</div>
				</div>
				<div className="benefit-card group">
					<div className="p-5 pb-0" style={{backgroundImage: `url(${benefit})`}}>
						<div className="h-80 benefit-card__image"  style={{backgroundImage: `url(${image3})`}}></div>
					</div>
					<div className="benefit-card__textarea">
						<p className="font-bold">Find help for all your legal needs</p>
						<p className="benefit-card__subtext">Find lawyers that suit your budget and preference to handle your legal matters. Also find re-usable templates that can help.</p>
					</div>
				</div>
			</div>
			<div className="xl:px-28">
				<div style={{backgroundImage: `url(${join})`}} className="bg-no-repeat bg-left bg-cover join-section px-5 py-10 sm:p-16 rounded-lg relative">
					<embed src={logo3d} alt="" className="absolute bottom-0 left-0 lg:bottom-7 lg:left-7 hidden md:block" />
					<div className="grid grid-cols-1 gap-5 sm:gap-10 sm:text-center">
						<h2 className="font-bold font-grotesk text-3xl sm:text-4xl">Join today to get early access</h2>
						<p className="text-lg">Sign up with only your email and be one of the first to be notified when Tabulerasa goes live. <br className="hidden sm:block" /> We promise not to spam your email ;)</p>
						<div className="flex justify-center flex-wrap">
							<input 
								type="text" 
								className={`form-control w-full !rounded-sm md:w-2/3 no-outline p-4`} 
								placeholder="Enter your email address" 
								ref={props.callToActionRef}
								value={email}
								onChange={(e) => {
									setError("")
									setEmail(e.target.value)}} />
								<div className="w-full md:w-2/3">
									<div className="form-error">{error}</div>
								</div>
						</div>
						<div className="text-center">
							<button 
								className="round-btn bg-app-orange-70 hover:bg-app-orange-80 px-8 py-3 text-app-black-100"
								disabled={(props.busy)}
								onClick={() => {
									if(validateEmail()){
										props.postEmail(email, setEmail)
									}
									}}>{props.busy? <span className="loader"></span>: "Sign up"}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}