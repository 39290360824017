import { useState } from "react";
import { useRef } from "react";
import { env } from "../env/env";
import Action from "../sections/Action";
import Footer from "../sections/Footer";
import Hero from "../sections/Hero";
import Product from "../sections/Product";
import SuccessModal from "../ui/modals/successModal";


export default function Landing(props) {
	const [busy, setBusy] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [message, setMessage] = useState("")
	const callToActionRef = useRef()

	async function postData(url = '', data = {}) {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				accept: 'application/json'
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}
	
	
	function postEmail(email, clear) {
		setBusy(true)
		postData( env.apiBaseUrl+'/waitlist', {email})
			.then(data => {
				setBusy(false)
				if(data.status){
					setMessage(data.message)
					setModalOpen(true)
				}
				clear("")
			})
			.catch((error => {
				setBusy(false)
				console.log(error);
			}));
	
	}

	function closeModal() {
		setModalOpen(false)
	}
	
	return(
		<div className="">
			<Hero busy={busy} postEmail={postEmail} />
			<Product callToActionRef={callToActionRef} />
			<Action callToActionRef={callToActionRef}  busy={busy} postEmail={postEmail} />
			<Footer />
			<SuccessModal isOpen={modalOpen} close={closeModal} message={message} />
		</div>
	)
}