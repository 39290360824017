import wavyblack from "./../assets/icons/wavyblack.svg"
import phone from "./../assets/icons/phone.svg"
import laptop from "./../assets/icons/laptop.svg"

export default function Product(props) {
	function scrollToCallToAction() {
		const callToAction = document.getElementById("call-to-action");
		callToAction.scrollIntoView({ block: 'end',  behavior: 'smooth' })
		props.callToActionRef.current.focus({preventScroll:true});
	}
	return(
		<div className="min-h-screen bg-no-repeat bg-left-top bg-cover py-20 px-5 lg:px-36 bg-app-black-100" style={{backgroundImage: `url(${wavyblack})`}}>
			<div className="grid gap-10 lg:gap-20 lg:grid-cols-2 grid-cols-1">
				<div className="flex justify-center lg:justify-start">
					<embed src={phone} alt="" />
				</div>
				<div className="justify-center lg:justify-start pt-3">
					<h3 className="text-app-orange-70 font-bold">FEED</h3>
					<p className="font-grotesk font-bold text-2xl lg:text-4xl my-5 text-app-black-10 leading-8 lg:leading-[56px]">Find useful and accurate information</p>
					<p className="text-lg text-app-black-40 leading-8">Connect with lawyers, ask questions, find useful and authentic information about legal topics and matters. Tabulerasa gives you access to a wide range of information about the law and how it works</p>
					<button className="round-btn bg-white px-10 py-3 text-app-black-100 mt-8" onClick={()=>scrollToCallToAction()}>Get Started</button>
				</div>
				
			</div>
			<div className="grid gap-10 lg:gap-20 lg:grid-cols-2 grid-cols-1 mt-20 lg:mt-28">
				<div className="lg:order-last flex justify-center lg:justify-end">
					<embed src={laptop} alt="" />
				</div>
				<div className="justify-center lg:justify-start pt-3">
					<h3 className="text-app-orange-70 font-bold">MARKETPLACE</h3>
					<p className="font-grotesk font-bold text-2xl lg:text-4xl my-5 text-app-black-10 leading-8 lg:leading-[56px]">Get access to top quality lawyers</p>
					<p className="text-lg text-app-black-40 leading-8">Find high quality lawyers for your legal needs. Tabulerasa connects you to high quality lawyers while also matching lawyers with the best cases based on their specialties</p>
					<button className="round-btn bg-white px-10 py-3 text-app-black-100 mt-8" onClick={()=>scrollToCallToAction()}>Get Started</button>
				</div>
			</div>
		</div>
	)
}