import { useState } from "react"
import { useRef } from "react"
import { emailValid } from "../atoms/utils"
import logo from "./../assets/icons/logo.svg"
import hero from "./../assets/images/hero1.png"

export default function Hero(props) {
	const inputRef = useRef()
	function focusOnInput() {
		inputRef.current.focus()
	}
	const[email, setEmail] = useState("")
	const[error, setError] = useState("")
	const empty = (email === "")

	function validateEmail() {
		if (empty) {
			setError("Please enter an email address")
			return false
		} else if (!emailValid(email)){
			setError("Please enter a valid email address")
			return false
		}else{
			return true
		}
	}

	return(
		<div className="min-h-screen pt-10 px-5 sm:px-16">
			<div className="flex sm:justify-center">
				<img src={logo} alt="" />
				<button className="sm:hidden ml-auto text-xs bg-app-grey-40 px-10 py-1 rounded" onClick={()=> focusOnInput()}>Sign up</button>
			</div>
			<div className="flex flex-wrap">
				<div className="sm:w-1/2 w-full py-20">
					<h1 className="font-grotesk text-5xl font-bold text-[40px] sm:text-[64px]">
						Find <span className="text-app-orange-80">Legal Help</span> & Information
					</h1>
					<p className="text-app-black-70 pt-10 pb-7 text-lg sm:text-xl">
						Join the community connecting people to legal resources. <br className="hidden sm:block" /> Sign up to get early access
					</p>
					<div className="mb-10">
						<label htmlFor="email" className="text-sm">Email address</label>
						<input 
							type="text" 
							className={`form-control !outline-none mt-3 p-4 bg-app-grey-10 border text-lg ${error ? "border-red-500" : "border-app-grey-40"}`}
							placeholder="Enter your email address" 
							ref={inputRef}
							value={email}
							onChange={(e) => {
								setError("")
								setEmail(e.target.value)}} />
						<div className="form-error">{error}</div>
					</div>
					<button className="round-btn bg-app-black-100 text-sm !px-16 !py-6" disabled={props.busy}
						onClick={() => {
							if(validateEmail()){
								props.postEmail(email, setEmail)
							}
						}}>{props.busy? <span className="loader"></span>: "Sign up"}</button>
				</div>
				<div className="sm:w-1/2 w-full pt-10">
					<embed src={hero} alt="" className="w-full" />
				</div>
			</div>
		</div>
	)
}