import logowhite from "./../assets/icons/logowhite.svg"
import fb from "./../assets/icons/fb.svg"
import ig from "./../assets/icons/ig.svg"
import twitter from "./../assets/icons/twitter.svg"
import linkedin from "./../assets/icons/linkedin.svg"

export default function Footer() {
	return(
		<div className=" bg-app-black-100 sm:px-20 py-10">
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-8 justify-items-center sm:justify-items-stretch sm:items-baseline">
				<div className="font-semibold text-app-grey-20 sm:text-center sm:col-span-2">Tabulerasa is launching soon!</div>
				<img src={logowhite} alt="" className="sm:absolute" />
				<div className="flex justify-center gap-5 sm:gap-2 sm:order-last sm:justify-end">
					<a href="https://www.linkedin.com/company/tabulerasa/" target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a>
					<a href="https://web.facebook.com/Tabulerasa-106233852122026" target="_blank" rel="noreferrer"><img src={fb} alt="" /></a>
					<a href="https://instagram.com/usetabulerasa?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><img src={ig} alt="" /></a>
					<a href="https://twitter.com/usetabulerasa?s=21&t=FK1j7bDR8uYbvdhOo_STrw" target="_blank" rel="noreferrer"><img src={twitter} alt="" /></a>
				</div>
				<p className="text-app-black-30 text-xs sm:align-bottom">© 2022 Tabulerasa | All rights reserved</p>
			</div>
		</div>
	)
}